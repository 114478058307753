<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { FormSubmitContext } from '~/ts/types/form'
    import { useUserStore } from '~/stores/user'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    definePageMeta({
        layout: 'auth',
        middleware: 'guest',
    })

    type FormRules = {
        email: AnyFn
        password: AnyFn
    }

    type FormValues = {
        email: string
        password: string
    }

    const emailRef = ref<ReturnType<typeof defineComponent>>()

    useFieldControl(
        emailRef,
        {
            nestedRefName: 'inputRef',
            startTyping: true,
            disabled: () => !!formValues.value.email,
        },
    )

    const route = useRoute()

    const formRules = shallowRef<FormRules>({
        email: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.email(value),
        ),
        password: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.between(value, { min: 8, max: 50 }),
        ),
    })

    const formValues = ref<FormValues>({
        email: route.query.email as string || '',
        password: '',
    })

    const pending = ref<boolean>(false)

    const userStore = useUserStore()

    const submit = async (values, { setFieldError }: FormSubmitContext): Promise<void> => {
        if (pending.value) {
            return
        }

        pending.value = true

        try {
            await userStore.login(values)
        } catch (error) {
            handleFormError({ error, setFieldError })
        } finally {
            pending.value = false
        }
    }
</script>

<template>
    <AuthPage :title="$t('sign-in')">
        <AuthSocialLinks />

        <AppDividerWithText class="my-2">
            {{ $t('or') }}
        </AppDividerWithText>

        <AppForm
            v-slot="{ errors }"
            :values="formValues"
            :rules="formRules"
            @submit="submit"
        >
            <AppFormFieldInput
                ref="emailRef"
                v-model.trim="formValues.email"
                v-model:error="errors.email"
                type="email"
                name="email"
                :label="$t('email')"
                autocomplete
                class="!w-[320px]"
            />

            <AppFormFieldInput
                v-model="formValues.password"
                v-model:error="errors.password"
                type="password"
                name="password"
                :label="$t('password')"
                class="!w-[320px]"
            />

            <AppLink
                invert
                :to="{ name: 'language-auth-reset-password-request' }"
                class="self-end mb-6"
            >
                {{ $t('forgot-password') }}?
            </AppLink>

            <AppButton
                type="submit"
                :loading="pending"
            >
                {{ $t('sign-in-with-email') }}
            </AppButton>

            <AuthFormBottomSection
                class="justify-around"
                :to="{ name: 'language-auth-sign-up' }"
            >
                <template #link-text>
                    {{ $t('register') }}
                </template>

                <template #hint-text>
                    {{ $t('dont-have-an-account-yet') }}?
                </template>
            </AuthFormBottomSection>
        </AppForm>
    </AuthPage>
</template>
